import * as React from 'react';
import { Container, Row, Col, Tooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './scss/Layout.scss';

const config = require('./config');

export class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tooltip1Open: false,
			tooltip2Open: false,
			tooltip3Open: false,
			donations: [false,false,false]
		}
		this.toggle = this.toggle.bind(this);
	}

	toggle(target) {
		this.setState({
			[target]: !this.state[target]
		});
	}

	componentDidMount() {
		//special cors policy in place to allow this on pillarist config
		fetch(config.donationsUrl, {
			headers : {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		}).then(response => {
			response.json().then(donations => {
				if (donations.current) {
					this.setState({ donations: donations.current });
				}
			});
		});		
	}

	render () {
		const loginWarning = (this.props.isLive && !this.props.authedUser) ? <Row><Col id="loginWarning"><div>Please log in using the link above to participate in live trivia<span>You do not need to log in to see live questions and answers, but your responses will not be scored.</span></div></Col></Row> : '';

		const adminLogin = (this.props.authedUser && this.props.isAdmin) ? <li className="adminLogin"><a href="/admin">Switch to Admin Panel</a></li> : '';

		const { donations } = this.state;

		return (
			<div>
				<div id="header">
					<Container>
						<Row>
							<Col md="8">
								<a href="/" className="title">trivia.computer</a>		
								<div className={'users ' + (this.props.users > 0 ? 'active' : '')}>{ this.props.users } { this.props.users > 1 ? 'users': 'user' }</div>
							</Col>
							<Col md="4">
								<ul className="navList d-flex justify-content-md-end">
								{
									(this.props.googleAuthUrl && !this.props.authedUser)
										? <li><a className="googleAuth" href={this.props.googleAuthUrl}>log in</a></li>
										: <li></li>
								}
								{
									(this.props.authedUser)
										? <><li className="userName">{this.props.authedUser}</li><li className="logout"><button onClick={this.props.handleLogout} title="Log Out"><FontAwesomeIcon icon="sign-out-alt" /></button></li></>
										: <li></li>									
								}
								</ul>
							</Col>
						</Row>

						{loginWarning}
					</Container>
				</div>
				
				{ this.props.children }
				
				<div id="footer">
					<Container>
						<Row>
							<Col sm="12" md={{ size: 10, offset: 1 }} className="donations">
								<div className="title">Donation Suggestions</div>
								<a href={donations[0] && donations[0].link ? donations[0].link : undefined} target="_blank" rel="noopener noreferrer" id="tooltip1">{donations[0] && donations[0].title}</a>
								<Tooltip placement="top" isOpen={this.state.tooltip1Open} target="tooltip1" toggle={() => this.toggle('tooltip1Open')}>{donations[0] && donations[0].desc}</Tooltip>

								<a href={donations[1] && donations[1].link ? donations[1].link : undefined} target="_blank" rel="noopener noreferrer" id="tooltip2">{donations[1] && donations[1].title}</a>
								<Tooltip placement="top" isOpen={this.state.tooltip2Open} target="tooltip2" toggle={() => this.toggle('tooltip2Open')}>{donations[1] && donations[1].desc}</Tooltip>

								<a href={donations[2] && donations[2].link ? donations[2].link : undefined} target="_blank" rel="noopener noreferrer" id="tooltip3">{donations[2] && donations[2].title}</a>
								<Tooltip placement="top" isOpen={this.state.tooltip3Open} target="tooltip3" toggle={() => this.toggle('tooltip3Open')}>{donations[2] && donations[2].desc}</Tooltip>
							</Col>

							<Col xs="12" className="content">
								<ul>
									<li>© {new Date().getFullYear()} Trivia Computer</li>
									{ adminLogin }
								</ul>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
}
