import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/App.scss';

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Computer } from './components/Computer';
import { GoogleAuth } from './components/GoogleAuth';
import { AdminInterface } from './components/AdminInterface';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faShare, faSignOutAlt, faEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
//import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
library.add(faShare, faSignOutAlt, faEdit, faCheck, faTimes);

class App extends Component {
	render() {
		return (
			<Switch>
				<Route path='/googleauth' component={GoogleAuth} />
				<Route path='/admin' component={AdminInterface} />
				<Route path='/' component={Computer} />
			</Switch>
		);
	}
}

export default App;